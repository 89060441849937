.news-line {
    &:not(:last-child) {
        padding-bottom: 40px;
    }
}

.news-shares,
.news-line {
    .news-shares-text {
        width: 75%;
        padding-bottom: 50px;
    }
    .head-info {
        text-align: left;
        width: 75%;
        padding-bottom: 12px;
        time {
            font-size: 16px;
            color: #898989;
            padding-bottom: 2px;
        }
        .news-heading {
            font-size: 18px;
            font-family: $fontBold;
            margin-bottom: 0;
        }
    }
    &:not(:last-child) {
        padding-bottom: 40px;
    }
    .content {
        padding: {
            left: 20px;
            right: 20px;
        }
        .image-container {
            display: none;
            .icon-logo {
                font-size: 0;
            }
        }
        h1 {
            font-size: 35px;
        }
        .shares {
            padding-top: 0;
            .sales {
                padding-top: 30px;
                li {
                    margin-bottom: 33px;
                    margin-top: 0;
                }
            }
        }
        @media (max-width: 992px) {
            padding: {
                left: 0;
                right: 0;
            }
            .shares {
                .sales {
                    @include minusMargins(6px);
                    li {
                        margin-bottom: 0;
                        padding-bottom: 33px;
                        &:last-child {
                            padding-bottom: 0;
                        }
                        &:nth-last-child(-2n+2) {
                            padding-bottom: 0;
                        }
                    }
                }
            }
            h1 {
                font-size: 24px;
                font-weight: normal;
                text-align: center;
                padding: 0;
                margin: 0;
                padding-bottom: 17px;
            }
            h1,
            h2 {
                line-height: 1;
            }
            h2:not(.text-center) {
                text-align: left;
                text-transform: none;
            }
            padding: {
                left: 0;
                right: 0;
            }
            .f-news-component {
                h2 {
                    text-align: center;
                }
            }
            .article {
                padding-bottom: 0;
                p {
                    line-height: 1.2;
                    text-align: left !important;
                    font-size: 18px;
                    color: #050505;
                }
            }
            .article:not(:last-child) {
                padding-bottom: 40px;
            }
            .image-container {
                display: block;
                min-height: 500px;
                background: {
                    size: cover;
                    position: bottom center;
                    repeat: no-repeat;
                }
                margin-bottom: 25px;
                position: relative;
                .icon-logo {
                    font-size: 150px;
                    position: absolute;
                    left: 50%;
                    top: 60%;
                    transform: translate(0%, -60%);
                }
            }
        }
        @media (max-width: 600px) {
            .image-container {
                .icon-logo {
                    font-size: 117px;
                }
            }
            .shares {
                .sales {
                    li {
                        &:nth-last-child(-2n+2) {
                            padding-bottom: 33px;
                        }
                    }
                }
            }
        }
        @media (max-width: 450px) {
            width: 100%;
            .image-container {
                min-height: 371px;
            }
            .news-shares-text,
            .head-info {
                width: 100%;
            }
        }
    }
    &.inverse {
        .sales {
            &:last-child {
                padding-bottom: 0;
            }
        }
        .content {
            padding: {
                left: 20px;
                right: 20px;
            }
            &:not(:last-child) {
                padding-bottom: 50px;
            }
            @media (max-width: 992px) {
                h1 {
                    font-size: 24px;
                    font-weight: normal;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                    padding-bottom: 17px;
                }
                h1,
                h2 {
                    line-height: 1;
                }
                h2 {
                    text-align: left;
                    text-transform: none;
                }
                padding: {
                    left: 0;
                    right: 0;
                }
                .f-news-component {
                    h2 {
                        text-align: center;
                    }
                }
                article {
                    padding-bottom: 0;
                    p {
                        line-height: 1.2;
                        text-align: left;
                        font-size: 18px;
                        color: #050505;
                    }
                }
                article:not(:last-child) {
                    padding-bottom: 40px;
                }
                .image-container {
                    display: block;
                    min-height: 500px;
                    background: {
                        size: cover;
                        position: bottom center;
                        repeat: no-repeat;
                    }
                    margin-bottom: 28px;
                    position: relative;
                    .icon-logo {
                        font-size: 150px;
                        position: absolute;
                        left: 50%;
                        top: 60%;
                        transform: translate(0%, -60%);
                    }
                }
            }
            @media (max-width: 600px) {
                .image-container {
                    .icon-logo {
                        font-size: 117px;
                    }
                }
            }
            @media (max-width: 450px) {
                width: 100%;
                .image-container {
                    min-height: 371px;
                }
            }
        }
        .side {
            @media (max-width: 992px) {
                float: none;
                width: 100%;
                padding-left: 0;
                display: table;
                .news-line {
                    display: table-footer-group;
                }
                .f-news-component {
                    padding-bottom: 50px;
                }
            }
        }
    }
}

.news-shares {
    .content {
        .list-view {
            .sales {
                padding-top: 0;
            }
        }
    }
}
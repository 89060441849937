        .product-prop-list{
          padding: {
            top: 23px;
            bottom: 40px;
            left: 20px;
            right: 20px;
          }       
          .table-title{
            span{
              color: #898989;
              font-size: 12px;
            }
          }
          li{
              &:not(:last-child){
                padding-bottom: 20px;
              }
              font-size: 0;
              span:first-child{
                width: 25%;
              }
              span:nth-child(2){
                width: 45%;
              }
              span:nth-child(3){
                width: 30%;
              }
              span{
                font-size: 16px;
                display: inline-block;
                vertical-align: middle;
              }
              
            
            .price-item{
              &:after{
                content: "грн.";
                font-size: 12px;
                padding-left: 7px;
              }
            }
            .amount-item{
              input{
                border-radius: 3px;
                &:focus{
                    border-color: $pink;
                    box-shadow: inset 0 1px 1px rgba($pink,.075), 0 0 8px rgba($pink,.6);                

                }
              }
            }
          }

        }
.dop-info{
  @media(max-width: 992px){
    text-align: center;
    h1{
      padding-top: 0;
      padding-bottom: 20px;
      text-align: center;
      font-size: 24px;
      &:first-letter{
        text-transform: uppercase;
      }
    }
    p{
      font-size: 18px;
      .gray{
        color: #898989;
      }

    }
  }
  @media(max-width: 768px){
    text-align: left;
    h1{
      text-align: left;
    }
  }


}

a.decoration{
  text-decoration: none;
  display: inline;
  @include text-underline($black30p, $black30p, $black30p);
  margin-left: 6px;
  &.special{
    margin-left: 0;
    color: $pink;
    @include text-underline($pink, $pink, $pink);
  }
}  

.detail-position{
    .zoom-img{
      text-align: center;  
      .zoom{
        img{
          z-index: 10;
        }
        img.main-image{
          z-index: 10;
        }
      }      
    }
  @media (max-width: 992px){
    
    .details{
      padding-bottom: 55px;
      padding-left: 20px;
      .detail-head{
        padding-right: 0;
        font-size: 18px;
        min-height: 0;
        margin-bottom: 25px;
        p:last-of-type{
          margin-bottom: 0;
        }
        p{
          a{
            display: block;
          }
          a.decoration{
            text-decoration: none;
            display: inline;
            @include text-underline($black30p, $black30p, $black30p);
            margin-left: 6px;
          }          
        }
      }
      .accordion-btn{
        color: rgba(#ffffff, .6);
        font-size: 0;
        transition: .4s;
        padding: {
          top: 25px;
          bottom: 25px;
          left: 20px;
          right: 20px;
        }
        background: {
          repeat: no-repeat;
          size: cover;
          position: center;
        }
        &.white{
          color: rgba(#000000, .6);
          border: 1px solid #cccccc;
          .spangroup{
            .color-title{
              color: #000000;
            }
            .icon-seagull-down{
              &:before{
                color: rgba(#000000, .6);
              }
            }
          }

        }
        &.special{
    
            .spangroup{
              &:last-child{
                .tip{
                  &:before{
                    content: "Розгорнути";
                  }
                }

              }

            }            
          
        }

        .spangroup{
          display: inline-block;
          vertical-align: middle;
     
          font-size: 12px;
          .color-title{
            font-size: 16px;
            text-transform: uppercase;
            color: #ffffff;
          }
          .icon-seagull-down{
            font-size: 18px;
            &:before{
              color: rgba(#ffffff, .6);
            }
          }
          &:first-child{
            width: 70%;
            span:first-child{
              display: inline-block;
              width: 100%;
              padding-bottom: 2px;
            }
          }
          &:last-child{
            width: 30%;
            font-size: 0;
            span{
              display: inline-block;
              vertical-align: middle;
              
              &.tip{
                text-align: right;
                width: 60%;
                &:before{
                  content: "Розміри та ціни";
                  font-size: 14px;
                  display: inline-block;
                }
              }
            }
            .icon-seagull-down{
              width: 40%;
              padding-left: 12px;
            }
          }
        }

        &.active{
          .spangroup{
            &:last-child{
              .icon-seagull-down{
                &:before{
                  content: "\e908"
                }
              }
              .tip{
                &:before{
                  content: "Згорнути";
                }
              }

            }

          }

        }

      }
      .accordion-panel{
            width: 100%;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.2s ease-out;        

        border: {
          left: 1px solid #cccccc;
          right: 1px solid #cccccc;
        }
        &:after{
          content: "";
          background-color: #cccccc;
          height: 1px;
          display: block;
        }
        &:not(:last-child){
          margin-bottom: 12px;
        }
        &.special{
          border: none;
          &:after{
            content: none;
          }
        }

      }
      .form-group{
        margin-top: 27px;
        margin-bottom: 0;
      }
      form{
        padding-bottom: 30px;
      }
    }

  }
  @media (max-width: 768px){
    .zoom-img{
      text-align: left;
      margin-bottom: 20px;

      
    }    
    .zoom-img, .details{
      width: 75%;
      float: none;
    }
    .details{
      padding-left: 0;
      form{
        padding-right: 0;
        
      }
    }
  }
  @media (max-width: 500px){
    .zoom-img, .details{
      width: 100%;
    }
    .details{
      .accordion-btn{
          .spangroup{
            &:first-child{
              width: 60%;
            }
            &:last-child{
              width: 40%;
            }
          }      
      }
    }

  }
  @media (max-width: 400px){
    .details{
      .form-group{
        .btn-normal{
          width: 100%;
        }
      }
    }
  }
}




.confirmation{
    @media (max-width: 992px){
  .form-nav-tab{
      &.delivery{

        .nav-tabs{
          padding: {
            top: 0;
            bottom: 30px;
          }
          li{
            width: 100%;
            
            a{
              padding: 0;
            }
            &:not(:last-child){
              padding-bottom: 20px;
            }
          }
          .w-icons-car, .w-icons-man{
            display: none;
          }
        }
      }

    }
    form.default{
      [name='#nav-tab-3']{
        padding-bottom: 40px;
      }

      .form-group{
        &.notice{
          span{
            padding-top: 10px;
          }
        }
        &.agree{
          text-align: left;
        }

      }
    }
  }


}


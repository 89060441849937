

.f-news-component{
  text-align: center;
  > a {
    text-decoration: none;
      padding-bottom: 30px;
  }
  &:not(:last-child){
  }

  h2{
    margin-bottom: 30px;
    text-align: center;
  }
  .f-years-list{
    text-align: center;
    font-size: 0;
    padding-bottom: 28px;
    li{
      display: inline-block;
      font-size: 18px;
      color: #a5a5a5;
      font-family: $fontBold;
      padding: {
        left: 12px;
        right: 12px;
      }
      a{
        padding-bottom: 2px;
        padding-left: 3px;
        padding-right: 3px;
        color: inherit;
        text-decoration: none;

      }
      &:first-child{
        padding-left: 0
      }
      &:last-child{
        padding-right: 0;
      }
      &.active{
        color: #000000;
        a{
          border-bottom: 2px solid $pink;
        }
        
  
  color: inherit;
  text-decoration: none;    }
    }
  }
    .news-covering{
      .news-list{
        padding-bottom: 0;
      }
    }
  .news-list{
    line-height: 1.2;
    li{
      &:first-letter{
        text-transform: uppercase;
        display: inlin-block;
        vertical-align: top;
      }
    }
    a{
      width: 100%;
      font-size: 18px;
      text-transform: lowercase;
      &:first-letter{
        text-transform: uppercase;
      }      
    }
    time{
      font-size: 14px;
      color: #898989;
      display: block;
      padding-top: 10px;
    }
    li:not(.link-wrap){
      padding-bottom: 25px;
      text-align: left;
    }
    width: 70%;
  }
  .link-wrap{
    text-align: right;
    padding-right: 20px;
    > a{
      color: #000000;
      font-size: 18px;
      display: inline-block;
      text-decoration: none;
      .anchor, .icon-arr-r{
        display: inline-block;
        vertical-align: middle;
      }
      .anchor{
        display: inline;
        @include text-underline($black30p, $black30p, $black30p);
      }
      .icon-arr-r{
        font-size: 13px;
        padding-left: 7px;
      }

    }

  }

  @media (max-width: 768px){
    .news-list{
      padding-top: 35px;

    }
    .text-center{
      display: inline;
      @include text-underline($black30p, $black30p, $black30p);
    }
  }

  @media (max-width: 600px){
    .news-list, .link-wrap{
      width: 100%;
      padding-right: 0;
    }
  }

  @media (min-width: 1090px){
    .f-years-list{
      li{
        cursor: pointer;
      }
    }
  }
}


.item-box, .catalog{
  .f-news-component{
    padding: {
      left: 20px;
      right: 20px;
    }    
    @media (max-width: 992px){
      padding: {
        left: 0;
        right: 0;
      }
    }    

  }

}
.side{
  .f-news-component{
    .news-list{
      width: 100%;
    }
  }
  @media (max-width: 992px){
    .f-news-component{
      .news-list{
        width: 70%;
      }
    }    
    
  }
  @media (max-width: 450px){
    .f-news-component{
      .news-list{
        width: 100%;
      }
    }    
    
  }

}

.cabinet-layout, .confirmation{
  .f-news-component{
    display: inline-block;
    .news-list{
      width: 100%;
    }
  }
}
.news-shares{
  .f-news-component{
    padding-bottom: 40px;
    .news-covering{
      .news-list{
        padding-top: 0;
      }

    }
  }
}


  .cabinet-layout, .confirmation{
    .container{
      padding: {
        left: 20px;
        right: 20px;
      }
    }
    .content{
        &:after{
          content: '';
          display: table;
          clear: both;
        }      
    }
      .cart-footer, .confirm-head{
        #total-price, #confirm-price{
            .strong{
              padding-right: 5px;
              padding-left: 10px;
              font-size: 25px;
              font-weight: normal;
              font-family: $fontBold;              
            }
  
        }
      }

      @media (max-width: 1200px){
              .cart-footer, .confirm-head{
                text-align: left;
                padding-bottom: 0;
                #total-price, #confirm-price{
                  .strong{
                    padding-left: 0;
                  }
                } 
              }
      }
    @media (max-width: 992px){
      h1{
        padding: 0;
        padding-bottom: 20px;
        font-size: 24px;
        text-transform: none;
        text-align: center;
      }
      aside{
        nav{
          ul{
            padding-left: 0;
          }
        }
      }
      .content{
        padding-left: 0;

      }
      .cart-footer, .confirm-head{
        a.link{
          margin-bottom: 30px;
          font-size: 18px;
          width: 100%;
        }

        #total-price, #confirm-price{
          padding-bottom: 30px;
            .meta{
              padding-right: 0;
            }
            .strong{
              font-size: 18px;
              &.pink{
                font-size: 24px;
              }
            }
            .mobile-amount{
              .strong{
                  font-family: $fontRegular;
              }
            }
            .mobile-strong{
              font-size: 18px;
              font-family: $fontBold;
            }
        }
      }

  }
 
  @media (max-width: 800px){
    aside{
      width: 100%;
      float: none;
      padding-bottom: 20px;
      nav{
        ul{
          li{
            a{
              text-transform: none
            }
          }
        }

      }
    }
    .content{
      width: 100%;
      float: none;
    }

  }
   @media (max-width: 450px){

      .cart-footer, .confirm-head{
        p{
          text-align: center;
        }        
        .btn-normal{
          width: 100%;
        }
      }
   }

}
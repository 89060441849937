
  form.default, form.default.check-in, .cabinet-layout .content form.default{
    .form-group{
      .control-label{
    

          padding-top: 0;
          
        
      }
      .form-control{
         label{
           padding-top: 0;
           padding-bottom: 8px;

         }

      }
    }
    @media (max-width: 992px){
      padding-bottom: 37px;
      .form-group{
        &:last-child{
          text-align: right;
        }
        width: 100%;
      } 
    }


    @media (max-width: 640px){
      .form-group{
        width: 100%;
        &:not(:last-child){
          margin-bottom: 30px;
        }
        &:last-child{
          margin-bottom: 0;
        }
        
        .form-control, .control-label{
          width: 100%;
        }
        .form-control{
          input, .cuSelect2 .select2-choice{
            height: 55px;
            border-radius: 5px;
          }
        }
        .control-label{
          padding-top: 0;
          padding-bottom: 8px;
          font-size: 18px;
        }
        .errorMessage{
          font-size: 14px;
        }
        &.error{
          .errorMessage, .control-label{
            color: $pink;
          }
          .form-control{
            input{
              border-color: $pink;
            }
          }
        }
        button{
          height: 55px;
          width: 235.3px;
        }
      }

      .cuSelect2{
        .select2-choice{
          .select2-chosen{
            padding: 10px 12px;
            font-size: 18px;
            color: rgba(#000000, .7);
          }
          .select2-arrow{
            border-left: none;
            b{
              vertical-align: bottom;
            }
          }
        }
      }  

    }
    @media (max-width: 450px){
      .form-group{
        .form-control{
          label{
            color: rgba(#000000, .7);
          }
          .day{
            width: 25%;
          }
          .month{
            width: 75%;
            padding-right: 0;
          }
          .year{
            padding-top: 19px;
            width: 42%;
          }
          .street{
            width: 100%;
          }
          .flat, .house{
            width: 40%;
            padding-top: 19px;
          }
          .house{
            padding-left: 0;
          }
        }
        button{
          width: 100%;
        }

      }

    }
  }


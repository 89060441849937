
header{
    @media (max-width: 992px){
        .my-dropdown-submenu{
            box-shadow: none;
            padding-bottom: 220px;
            padding-top: 20px;
            padding-left: 0;
            left: 0;
            top: 125px;
            width: auto;
            border: none;
            position: fixed;
            height: 100%;
            overflow: auto;
            &:before{
                content: none;
            }
            .helper{
                padding-right: 0;
                .block{
                    padding-left: 0;
                    padding-bottom: 50px;
                    width: 100%;
                    float: none;
                    h2{
                        text-transform: none;
                        margin-bottom: 0;
                        padding-bottom: 17px;
                        padding-left: 40px;
                        padding-right: $space;
                    }
                    ul {
                        padding-left: 0;
                        li{
                            width: 75%;
                            margin: 5px 0;
                            a{
                                font-size: 16px;
                                padding: {
                                    left: 40px;
                                    right: 25px;
                                    top: 7px;
                                    bottom: 7px;
                                }
                                text-transform: lowercase;
                                color: #000000;
                            }
                        }
                    }
                    &+.block{
                        border: none;
                        padding-left: 0;
                        ul{
                            li{
                                a {
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
                .banner{
                    
                        display: block;
                        position: static;
                        width: auto;
                        text-decoration: none;
                        padding-bottom: 0;
                        padding-left: 40px;
                        padding-right: $space;
                        img{
                            display: none;
                        }
                        .title{
                            display: block;
                            position: static;
                            text-transform: none;
                            padding-top: 0;
                            color: $pink;
                            br {
                                display: none;
                            }
                        }
                    
                }
            }
        }
    }
}


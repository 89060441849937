.recent-position {
    >a {
        text-decoration: none;
    }
}

.recent-position:first-child {
    @media (max-width: 768px) {
        padding-top: 0;
        &.unBorder {
            &:not(:last-child) {
                margin-bottom: 35px;
            }
        }
    }
    .recent-slider {
        @media (max-width: 992px) {
            &.position-list {
                a:hover {
                    .hover {
                        box-shadow: none;
                        padding: 0;
                    }
                }
            }
        }
        @media (max-width: 600px) {
            @include minusMargins(4.5px);
            font-size: 0;
            padding-top: 30px;
            padding-bottom: 0;
            &.position-list {
                a {
                    height: auto;
                }
            }
            .slide {
                display: inline-block;
                vertical-align: top;
                height: auto;
                width: 33%;
                padding: {
                    left: 4.5px;
                    right: 4.5px;
                    top: 0;
                    bottom: 0;
                }
                margin: {
                    bottom: 25px;
                }
                .hover {
                    position: static;
                    .img {
                        margin-bottom: 6px;
                        img {
                            margin: {
                                left: auto;
                                right: auto;
                            }
                            display: block;
                            max-width: 135px;
                        }
                    }
                    .img,
                    .description {
                        text-align: left;
                    }
                    .title {
                        line-height: 1.2;
                    }
                    .description {
                        width: 135px;
                        margin: {
                            left: auto;
                            right: auto;
                        }
                        .info,
                        .title {
                            font-size: 14px;
                        }
                        .info {
                            color: #949494;
                        }
                        .price {
                            font-size: 14px;
                        }
                    }
                }
                &.special {
                    position: relative;
                    width: 100%;
                    margin-bottom: 0;
                    min-height: 411px;
                    padding: {
                        left: 0;
                        right: 0;
                    }
                    .hover {
                        position: static;
                        .img {
                            margin-bottom: 6px;
                            img {
                                margin: {
                                    left: auto;
                                    right: auto;
                                }
                                display: block;
                                max-width: none;
                                width: 100%;
                            }
                        }
                    }
                    .description {
                        z-index: 2;
                        width: 100%;
                        display: block;
                        position: absolute;
                        text-align: center;
                        @include center();
                        .title {
                            font-family: $fontBold;
                            font-size: 36.6px;
                            color: #ffffff;
                            text-transform: uppercase;
                            padding-bottom: 13px;
                        }
                        .info,
                        .art {
                            font-size: 18px;
                            color: #ffffff;
                        }
                        .info {
                            padding-bottom: 20px;
                        }
                        .price {
                            color: #ffffff;
                            font-size: 18px;
                            strong {
                                font-size: 24px;
                                color: #ffffff;
                            }
                        }
                    }
                }
                &.newest {
                    .title {
                        position: relative;
                        &:before {
                            content: "НОВИНКА";
                            display: block;
                            padding-bottom: 30px;
                            width: 97px;
                            text-align: center;
                            height: 27px;
                            margin: 0 auto;
                            background-color: $pink;
                            padding: 7px 12px;
                            margin-bottom: 20px;
                            color: #ffffff;
                            font-size: 14px;
                            font-family: $fontBold;
                        }
                    }
                    .img {
                      &:after {
                          content: '';
                          position: absolute;
                          top: 0;
                          bottom: 0;
                          left: 0;
                          right: 0;
                          width: 100%;
                          height: 100%;
                          background: rgba(#000000, .7);
                          z-index: 1;
                      }
                    }
                }
                &.even {
                    display: none;
                }
                &:nth-last-child(-2n+2) {
                    margin-bottom: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        @media (max-width: 460px) {
            .slide {
                width: 50%;
                .hover {
                    position: static;
                    .img {
                        margin-bottom: 6px;
                        img {
                            max-width: 135px;
                        }
                    }
                }
            }
        }
    }
}

.recent-position:first-child {
    @media (max-width: 768px) {
        text-align: center;
        .text-center {
            text-transform: none;
            display: inline;
            @include text-underline($black30p, $black30p, $black30p);
        }
    }
}

.recent-position.special {
    @media (max-width: 992px) {
        &.product-page {
            background-color: rgb(247, 247, 247);
            .recent-slider {
                .slide {
                    .hover {
                        .description {
                            .title,
                            .price {
                                color: #000000;
                                strong {
                                    color: #000000;
                                }
                            }
                        }
                    }
                }
            }
            border: none;
            .text-center {
                color: #000000;
            }
        }
        &:not(:last-child) {
            margin-bottom: 40px;
        }
        .recent-slider {
            margin-bottom: 0;
        }
    }
    @media (max-width: 768px) {
        @include minusMargins($space);
        .recent-slider {
            padding-top: 36px;
            padding-bottom: 40px;
            &.position-list {
                a:hover {
                    .hover {
                        box-shadow: none;
                        padding: 0;
                        background: none;
                    }
                }
                a {
                    height: auto;
                }
            }
            .slick-list {
                padding: 0;
                .slick-active:nth-child(1) {}
                .slick-active+.slick-active {}
            }
            .slide {
                display: inline-block;
                vertical-align: top;
                height: auto;
                width: 185px;
                padding: {
                    left: 25px;
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
                .hover {
                    position: static;
                    .img {
                        margin-bottom: 6px;
                        img {
                            width: 100%;
                        }
                    }
                    .img,
                    .description {
                        text-align: left;
                    }
                    .title {
                        line-height: 1.2;
                        color: #ffffff;
                    }
                    .description {
                        .info,
                        .title {
                            font-size: 14px;
                        }
                        .info {
                            color: #949494;
                        }
                        .price {
                            font-size: 14px;
                            color: #ffffff;
                            strong {
                                color: #ffffff;
                            }
                        }
                    }
                }
            }
        }
        background: #000000;
        padding-bottom: 0;
        margin-bottom: 40px;
        text-align: center;
        padding-top: 35px;
        .text-center {
            text-transform: none;
            display: inline;
            color: #ffffff;
            @include text-underline($black30p, rgba(255, 2555, 255, .3), rgba(255, 2555, 255, .3));
        }
    }
}
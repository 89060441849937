.f-product-description, .f-notice{
  
    p{
      font-size: 18px;
      line-height: 1.2;
    }
    p:not(:last-child){
      padding-bottom: 25px;
    }
  
  .consist{
    color: #898989;
  }

}
.f-product-description{
  padding-bottom: 25px;
  div:not(:last-child){
    padding-bottom: 25px;
  }
}
.f-notice{
  color: #898989;
}

.slick-slider {
    &.main-fade {
        .item:not(.slick-active):not(.slick-center) {
            .caption {
                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.8);
                    display: block;
                }
                background: {
                    size: cover;
                    repeat: no-repeat;
                }
            }
        }
        .item.slick-active,
        .item.slick-center {
            .caption {
                background: {
                    size: cover;
                    repeat: no-repeat;
                }              
                .container {
                    display: block;
                }
            }
        }
        .item {
            .caption {
                .container {
                    .badge-wrap {
                        display: none;
                        margin-bottom: 25px;
                        margin-right: -$space;
                        width: 134px;
                        height: 44px;
                        img {
                            margin-bottom: 0;
                            width: 100%;
                            height: auto;
                            display: block;
                        }
                    }
                }
            }
        }
        @media (max-width: 992px) {
            margin-top: -20px;
            .item:not(.slick-active),
            .item:not(.slick-center),
            .item.slick-active,
            .item.slick-center {
                height: 410px;
                .caption {
                    background: {
                        // size: cover;
                        // repeat: no-repeat;
                        position: 60%;
                    }
                    .badge-wrap {
                        display: inline-block;
                        position: relative;
                        background-color: #2D2B30;
                    }
                }
                .icon-logo{
                  font-size: 52px;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  &:before{
                    color: #ffffff;
                  }
                }
            }
            .item {
                .caption {
                    h2 {
                        font-size: 36.6px;
                        margin-bottom: 40px;
                        padding-right: 6px;
                    }
                    .container {
                        padding-right: $space;
                    }
                    .pink-btn {
                        width: 100%;
                        border-radius: 0;
                        height: 49px;
                    }
                }
            }
            .slick-dots {
                li {
                    button {
                        width: 14.5px;
                        height: 14px;
                    }
                    &.slick-active {
                        button {
                            background: $pink;
                        }
                    }
                }
            }
        }
        @media (max-width: 768px) {
            .item:not(.slick-active) {
                .caption {
                    &:after {
                        content: none;
                    }
                }
            }
        }
        @media (max-width: 450px) {
            .item {
                .caption {
                    .container {
                        .help {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
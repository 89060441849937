              .mark{
                
                @media (max-width: 992px){
                  position: absolute;
                  color: #ffffff;
                  padding: {
                    left: 7px;
                    right: 7px;
                    top: 5px;
                    bottom: 5px;
                  }
                  height: 21px;
                  text-transform: uppercase;
                  &.free{
                    background-color: #22a6c4;
                  }
                  &.free, &.new{
                    top: 7px;
                    right: 9px;
                  }
                  &.new{
                    background-color: $pink;
                  }
                  &.sale{
                    top: 34px;
                    right: 9px;
                    background-color: #99228c;
                  }


                }
              }



.catalog{
  .col.filters{
    padding-left: 20px;
    top:0;

  }
        .filter-not-found{
          height: 100vh;
        }
  
  .col.position{  
    padding-right: 20px;
    .sort-kind{
      font-size: 16px;
      font-family: $fontBold;
      color: #000000;
      &:after{
        content: ":";
        display: inline-block;
        vertical-align: text-top;
      }
    }
    .sort-filter-wrap{
      display: inline-block;
      vertical-align: middle;
      padding-right: 20px;
    }
  }

  @media (max-width: 992px){
    #filter-form{
      .col.filters{
        transform: translate(-273px, 0);
        width: 273px;
        position: fixed;
        height: 100% !important;
        top: 175px;
        left: 0;
        background-color: #ffffff;
        transition: transform 0.5s;
        overflow-x: hidden;
        z-index: 102;
        padding-bottom: 240px;
        &.open{
          transform: translate(0, 0);
        }
        .filters-list{
          padding: {
            left: $space;
            right: $space;
            top: 30px;
          }
          &:after{
            content: none;
          }
          &:before {
            background: none;
          } 
        }
        padding-left: 0;
        .filter{
          ul{
            li{
              width: 70%;
              font-size: 16px;
                .icr__text{

                  text-transform: lowercase;
                  &:first-letter{
                    text-transform: uppercase;
                  }                  
                }              
            }
          }
          &:nth-child(4){
            ul{
              li{
                width: 50%;
                padding-right: 20px;
                &:nth-child(even){
                  width: 40%;
                  padding-right: 0;
                }
              }
            }

          }

        }
        .filter:not(:last-child){
          margin-bottom: 40px;
        }
        .filter:last-child{
          margin-bottom: 0;
        }
        h4, h5{
          text-transform: lowercase;
          &:first-letter{
            text-transform: uppercase;
          }
        }
        h5{
          margin-bottom: 0;
          padding-bottom: 17px;
        }
        .control-panel{
          padding-bottom: 35px;
          margin-bottom: 25px;
          .reset-all, .report-number{
            font-size: 16px;
          }
          .report-number{
            margin-bottom: 30px;
          }
          .reset-all{
            a{
              .w-icons-reset{
                top: 6px;
              }
            }
          }
          ul.deleted{
            margin-bottom: 30px;
            li{
              font-size: 16px;
                text-transform: lowercase;
                &:first-letter{
                  text-transform: uppercase;
                }                
              a{

                
                .w-icons-delete{
                  top: 3px;
                }
              }
            }
          }
        } 

      }
    }

    .customCheck.customCheckColor{
      li{
        width: 43px !important;
      }
      .colorPicker{
          width: 32px;
          height: 32px;        
      }
      .icr.checked{
        .colorPicker{

          &:after{
            left: 12px;
            top: 10px
          }
        }

      }

    }
    .col.position{
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      .sort-filter-wrap{
        span{
          display: inline-block;
          vertical-align: middle;
        }      
      }

      h1{
        font-weight: normal;
        text-align: center;
        margin: 0;
        padding: {
          left: 0;
          right: 0;
          top : 0;
          bottom: 10px;
        }
      }
      .position-filter{
        ul.mobile-adopt{
          display: none;
          position: absolute;
          width: 199px;
          right: -25px;
          top: 64px;
          max-height: 260px;
          overflow-y: auto;
          @media screen and (orientation:landscape) {
            top: -100px;
          }
          .sort-filter-wrap{
            width: 100%;
            text-align: left;
            padding-bottom: 30px;
            font-size: 0;
          }
          z-index: 1001;
          background: #ffffff;
          padding: {
              left: $space;
              right: $space;
              top: 20px;
              bottom: 30px;
          }
          li {
            display: block;
            margin: 0;
            .icr__text{
              padding-left: 0;
                height: auto;
                display: block;
                font-size: 16px;
                color: #000000;
                padding: {
                    top: 7px;
                    bottom: 7px;
                }
            }
            .icr{
              &.checked{
                .icr__text{
                  border-radius: 0;
                  background: #ffffff;
                  color: $pink;
                  box-shadow: none;
                }
                
              }
            }
          }

        }      
      }

      
      .sort-kind{
        &:after{
          content: '';
        }
      }
      .position-filter{
        font-size: 0;
        padding: {
          top: 0;
          bottom: 25px;
        }
        border-bottom: 0;
      }
      .sort-filter-wrap{
        width: 50%;
        text-align: center;
        padding-right: 0;
      }
      .report-number{
        font-size: 16px;
        text-align: center;
        padding-bottom: 30px;
      }

      .icon-filter, .icon-sort{
        font-size: 19px;
        padding-right: 7px;
      }
    }
      .position-list{
        padding-top: 0;
        @include minusMargins(4.5px);
        li{
          height: auto;
          padding: {
            top: 0;
            bottom: 0;
            right: 4.5px;
            left: 4.5px;
          }
          a{
            height: auto;
            .hover{
              position: static;
              .img{
                margin-bottom: 6px;
              }
              .mark{
                padding: {
                  left: 7px;
                  right: 7px;
                  top: 6px;
                  bottom: 5px;
                }
                height: 21px;
                &.free, &.new{
                  top: 7px;
                  right: 9px;
                }
                &.sale{
                  top: 34px;
                  right: 9px;
                }
              }
              .description{
                .info, .title{
                  font-size: 14px;
                }
                .info{
                  color: #949494;
                }
                .price{
                  font-size: 14px;
                }
              }              
            }
            &:hover{
              .hover{
                box-shadow: none;
                padding: 0;
                left: initial;
                top: initial;
              }
            }
          }
        }
      }

  }
    @media (max-width: 768px){
      .position-list{
        li{
          width: 33%;
          margin-right: 0;
          &:nth-child(4n)+li{
            clear: none;
          }
          min-height: 235px;
          overflow: hidden;

        }
      }
    }
    @media (max-width: 450px){
      .position-list{
        li{
          width: 50%; 
        }
      }     
    }
} 




.news-line.two-columns{
  > a {
    text-decoration: none;
  }
  .sales{
    li{
      a{
        min-height: 277px;
        background: {
          size: cover;
          repeat: no-repeat;
          position: center;
        }

      }
      &:last-child, &:nth-last-child(-2n+2){
        margin-bottom: 0;
      }

    }

  }
  @media (max-width: 768px){
    text-align: center;
    padding: 0;
    .text-center{
      // text-transform: none;
      display: inline;
      @include text-underline($black30p, $black30p, $black30p);
    }    

    &:not(:last-child){
      padding-bottom: 40px;
    }
    .sales{
      padding-top: 30px;
      li{
        margin: 0;
        a{
          .caption{
            font-size: 18px;
            padding: {
              top: 16px;
              bottom: 16px;
              left: 16px;
              right: 16px;
            }
            line-height: initial;
          }
        }
      }
    }
  }
  @media (max-width: 500px){
    .sales{
      li{
        width: 100%;
        a{
          min-height: 182px;
          .caption{
            background: #000000;
          }
        }
        &:not(:last-child){
          padding-bottom: 34px;
        }
      }
    }
  }
}
.news-line{
  @media (max-width: 992px){
    a {
      text-decoration: none;
    }
    padding-bottom: 0;
    &:not(:last-child){
      padding-bottom: 40px;
    }
  }
  
}
.news-line:not(.two-columns), .news-line.inverse .side{
    .text-center{
      display: none;
    }
    .sales{
      li{
        a{
          .image-container{
            min-height: 194px;
            background: {
              size: cover;
              repeat: no-repeat;
              position: center;
            }            
          }
          


        }
      }
    }  
  @media (max-width: 992px){
    text-align: center;
    // padding-top: 0;
    .text-center{
      text-transform: uppercase;
      display: inline-block;
    }
    .sales{
      padding-left: 0;
      margin-left: 0;
      padding-bottom: 0;
      li, li:last-child{
      
          width: 33%;
          padding: 0 6px;
        
      }
      li{
        padding: 0 6px;
      }
      a{
        text-align: left;
            .caption.special{
              position: static;
              background: none;
              color: #000000;
              font-size: 18px;
              line-height: 1.2;
              font-family: $fontRegular;
              padding: 0;
            }
      }
    }
  }
  @media (max-width: 768px){
    .text-center{
      text-transform: none;
      display: inline;
      @include text-underline($black30p, $black30p, $black30p);
    }    
    .sales{
      padding-top: 30px;
      li, li:last-child{
      
          width: 50%;
          &:first-child{
            margin-top: 0;
          }
          &:nth-child(2){
            margin-top: 0;
          }
      }
    }
  }  
  @media (max-width: 450px){
    .sales{
      li, li:last-child{
          margin-top: 30px;
          width: 100%;
          &:first-child{
            margin-top: 0;
          }
          &:nth-child(2){
            margin-top: 30px;
          }          
          a {
            text-decoration: none;
            text-align: left;
            .caption.special{
              position: static;
              background: none;
              color: #000000;
              font-size: 18px;
              line-height: 1.2;
              font-family: $fontRegular;
              padding: 0;
            }
          .image-container{
            margin-bottom: 5px;
          }

            
          }
      }
    }
  }    
}

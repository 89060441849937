
.normal-layout {
  
  .mobile-footer{
    text-align: center;
    background: $lGray;
    padding: {
      top: 30px;;
      left: 0;
      right: 0;
      bottom: 30px;
    }
    &:before{
      content: none;
    }
    .footer-list{
      li{
        a, p{
         
          opacity: .7;
          color: #000000;
        }
        a{

           text-decoration: none;
        }
      }
      .tell-item{
        font-size: 18px;
        a{
          display: block;
          padding: {
            top: 8px;
            bottom: 8px;
          }        
        }

        margin: {
          top: 4px;
          bottom: 4px;
        }
      }
      .mail-item{
        font-size: 18px;
        margin-top: 12px;
        margin-bottom: 26px;
        a{
          
          display: inline;
          @include text-underline($black30p, $black30p, $black30p);        
        }
      }
      .info-item {
        font-size: 14px;
        padding-bottom: 30px;
      }


      .mirro-item{
        font-size: 14px;
 
        a{
          position: relative;
          margin-left: -55px;
          .icon-mirrolab{
            font-size: 55px;
            position: absolute;
            top: -20.5px;
            padding-left: 6px;
          }
        }
        

              
      }
    }
  }

  footer.desktop-footer{
    .links-bar{
      padding: {
        left: 20px;
        right: 20px;
      }
    }
    .copyright{
      padding-left: 20px;
    }
    .graphin{
      padding-right: 20px;
    }
    @media (max-width: 992px){
      .links-bar{
        padding: {
          left: 0;
          right: 0;
        }
        .block-20{
          width: 30%;
        }
      }
      .copyright{
        padding-left: 0;
        width: 50%;
      }
      .graphin{
        padding-right: 0;
        width: 30%;
      }
      .with-ic{
        padding-left: 25px;
      }
    }
  }
}

body{
  &.remodal-is-active{
    @media (max-width: 992px){
      .remodal{
        min-height: 0;
      }
    }
    @media (max-width: 600px){
      .remodal{
        min-height: 0;
        width: 100%;
        border-radius: 0;
      }
    }
    @media (max-width: 500px){
      .remodal{

            .btn-normal{
              width: 100%;
              margin-bottom: 20px;
              
            }
            .btn-normal +.link{
              margin-left: 0;
            }
      }
  
    }
    @media (max-width: 350px){
      form.default{
        .form-group{
          .control-label, {
            padding-bottom: 10px;
          }
          .form-control, .control-label{
            width: 100%;
          }
        }
      } 
    }
  }
}
#cart-block{
  .mobile-cart{
    @include minusMargins(5px);
    font-size: 0;
  }
}

.cart-list{
  position: relative;
  width: 270px;
  margin-right: 5px;
  margin-left: 5px;
  padding: {
    left: 16px;
    right: 16px;
    bottom: 25px;
    top: 20px;
  }
  display: inline-block;
  vertical-align: top;
  border: 1px solid #cccccc;
 
    margin-bottom: 30px;
 
  .f-close{
    right: 7px;
    top: 12px;
  }
  .title{
    font-size: 16px;
    font-family: $fontBold;
    max-width: 90%;
    padding-bottom: 15px;
      &:first-letter{
        text-transform: uppercase;
      }
    a{
      color: #000000;
      text-decoration: none;
    }
  }
  li{
    font-size: 0;
    .spangroup{
      display: inline-block;
      vertical-align: top;
      &:first-child{
        width: 56%;
        img{
          width: 100%;
          max-width: 100%;
          display: block;
          margin: 0 auto;
        }
      }
      &:last-child{
        width: 44%;
        padding-left: 15px;
        > span:not(:nth-child(2n)):not(:last-child){
          color: #898989;
          padding-bottom: 4px;
          padding-left: 10px;
        }
        > span{
          font-size: 13px;
          display: block;
          &:nth-child(2n){
            padding-left: 10px;
            color: #000000;
            padding-bottom: 20px;
            text-transform: lowercase;
            &:first-letter{
              text-transform: uppercase;
            }
          }
          &:last-child{
            color: #ffffff;
            padding-bottom: 0;
            border-radius: 5px;
            padding: {
              left: 10px;
              right: 10px;
              top: 8px;
              bottom: 8px;
            }
            background: {
              repeat: no-repeat;
              size: cover;
              position: center;
            }
            span:first-child{
              display: block;
            }
            span:last-child{
              text-transform: uppercase;
            }
          }
        }
      }
    }
    &.card-head{
      padding-bottom: 20px;
    }
    input{
      width: 100%;
      display: block;
      transition: all .1s linear 0s;
      padding: 5px 10px;
      color: #000;
      height: 32px;
      outline: 0px solid #fff !important;
      border: 1px solid #cbcbcb;
      background-clip: padding-box;
      background-color: #fff;      
    }
    .product-prop-list{
      padding: {
        top: 18px;
        bottom: 18px;
      }
      @include minusMargins(15px);
      border: {
        bottom: 1px solid #cccccc;
        top: 1px solid #cccccc;
      }
    }
    &.card-foot{
        padding-top: 28px;
        font-size: 0;
        > span {
          display: inline-block;
          vertical-align: middle;
        }
      > span:first-child{
        font-size: 12px;
        font-family: $fontRegular;
        color: #898989;
        width: 55%;
        overflow: hidden;
        &:after{
          content: '';
          display: inline-block;
          height: 1px;
          width: 100%;
          margin-right: -100%;
          border: 1px dotted #898989;
        }
        
      }
      > span:last-child{
        font-size: 16px;
        width: 45%;
        font-family: $fontBold;
        padding-left: 4px;
        &:after{
          content: "грн.";
          font-size: 12px;
          font-family: $fontBold;
          padding-left: 7px;          
        }
      }
    }
  }
  @media (max-width: 600px){
    margin-right: 0;
    margin-left: 0;
  }
  @media (max-width: 400px){
    width: 100%;
  }
}



body, html{
  overflow-x: hidden;
}


.f-info-window{
  display: inline-block;
  background-color: #ffffff;
  padding: 4px 13px;
}


.main-fade{
  visibility: hidden;
  .item{
    visibility: hidden;
  }
  &.slick-initialized{
    visibility: visible;
    .item{
      visibility: visible;
    }
  }
}

@media (max-width: 992px){
  h2, h1{
    font-size: 24px;
    text-transform: none;
    padding-top: 0;
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6{
    line-height: 1.2;
  }
  .icon-logo {
      font-size: 76px;
  }
    
  
}

.upper{
  text-transform: uppercase;
  @media (max-width: 600px){
    text-transform: none;
  }
}


[class^="icon"]{
  &:before{
    color: #000000;
  }
}



    @media (max-width: 993px){
        .overlay{
                display: none;
                content: '';
                position: fixed;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                z-index: 104;
                background-color: rgba(0,0,0,0.5);
            }
    }
.f-close {
    position: absolute;
    width: 30px;
    height: 30px;
    opacity: 1;
    cursor: pointer;
    &:hover {
        opacity: 0.7
    }
    &:before,
    &:after {
        position: absolute;
        left: 13px;
        top: 8px;
        content: ' ';
        height: 15px;
        width: 3px;
        background-color: rgb(104, 104, 104)
    }
    &:before {
        transform: rotate(45deg)
    }
    &:after {
        transform: rotate(-45deg)
    }
    @media (min-width: 993px){
        &:not(.special){
            display: none;
        }
    }
    @media (min-width:1201px){
        &.special{
            display: none;
        }
    }

}
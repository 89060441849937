.pagination{
  li{
    text-align: center;
    font-size: 0;
    width: initial;
    height: 34px;

    a{
      padding: {
        top: 9px;
        bottom: 9px;
        left: 13px;
        right: 13px;
      }
      display: inline-block;
      vertical-align: middle;
      &:after{
        vertical-align: top;
      }
    }
    &.previous, &.next{
      a{
        text-decoration: none;
        &:before{
          content: '...';
        }      
      }

    }
  }
  @media (max-width: 992px){

      padding-bottom: 40px;
    

  }
}
.news-shares{
  .pagination{
    padding-top: 0;
    padding-bottom: 50px;
    &:last-child{
      padding-bottom: 0;
    }
  }
}
.confirmation{
    .container{
      padding: {
        left: 20px;
        right: 20px;
      }
    }  
    @media (max-width: 992px){
      .confirm-head{
        #confirm-price{
          .link{
            
            margin-bottom: 35px;
            margin-top: 25px;
            text-align: left;
          }
          padding-bottom: 0;
          padding-left: 20px;
          padding-top: 25px;
          .pink{
            color: $pink;
          }
        }
      } 
      padding-top: 0;
      form{
        h5{
          padding-top: 0;
          padding-bottom: 20px;
          
        }
      }      
      .block{
        width: 70%;
        float: none;
        &.pull-left{
          width: 100%;
          padding-right: 0;
          padding-bottom: 28px;
        }
        &.pull-right{
          padding-left: 0;
          padding-bottom: 0;
        }
        .link{
          width: 100%;
          margin-bottom: 35px;
          font-size: 18px;
        }
      }
      .cart-list{
        width: 280px;
      }

      .accordion-btn{
        display: none;
        color: rgba(#ffffff, .6);
        font-size: 0;
        transition: .4s;
        padding: {
          top: 25px;
          bottom: 25px;
          left: 20px;
          right: 20px;
        }
        &.white{
          color: rgba(#000000, .6);
          border: 1px solid #cccccc;
          .spangroup{
            .color-title{
              color: #000000;
            }
            .icon-seagull-down{
              &:before{
                color: rgba(#000000, .6);
              }
            }
          }

        }
        &.special{
    
            .spangroup{
              &:last-child{
                .tip{
                  &:before{
                    content: "Розгорнути";
                  }
                }

              }

            }            
          
        }

        .spangroup{
          display: inline-block;
          vertical-align: middle;
     
          font-size: 12px;
          .color-title{
            font-size: 16px;
            text-transform: uppercase;
            color: #ffffff;
          }
          .icon-seagull-down{
            font-size: 18px;
            &:before{
              color: rgba(#ffffff, .6);
            }
          }
          &:first-child{
            width: 70%;
            span:first-child{
              display: inline-block;
              width: 100%;
              padding-bottom: 2px;
              font-family: $fontBold;
              font-size: 16px;
              color: #000000;
            }
          }
          &:last-child{
            text-align: center;
            width: 30%;
            font-size: 0;
            span{
              display: inline-block;
              vertical-align: middle;
              
              &.tip{
                text-align: center;
                width: 100%;
                &:before{
                  content: "Розміри та ціни";
                  font-size: 14px;
                  display: inline-block;
                }
              }
            }
            .icon-seagull-down{
              width: 40%;
            }
          }
        }

        &.active{
          .spangroup{
            &:last-child{
              
              .icon-seagull-down{
                &:before{
                  content: "\e908"
                }
              }
              .tip{
                &:before{
                  content: "Згорнути";
                }
              }

            }

          }

        }

      }
      .accordion-panel{
            width: 100%;
            
            overflow: hidden;
            transition: max-height 0.2s ease-out;        

        border: {
          left: 1px solid #cccccc;
          right: 1px solid #cccccc;
        }
        &:after{
          content: "";
          background-color: #cccccc;
          height: 1px;
          display: block;
        }
        &:not(:last-child){
          margin-bottom: 12px;
          &.special{
            margin-bottom: 0;
          }
        }
        &.special{
          border: none;
          
          &:after{
            content: none;
          }
        }

      }


    }

  @media (max-width: 500px){
      .block{
        width: 100%;
        .link{
          text-align: center;
        }
      }

      .accordion-btn{
          .spangroup{
            &:first-child{
              width: 60%;
            }
            &:last-child{
              width: 40%;
            }
          }      
      }
      
  }
    @media (max-width: 400px){
      .cart-list{
        width: 100%;
      }
      .accordion-btn{
        display: block;
      }
      .accordion-panel{
        max-height: 0;
      }

    }


}
body, .container {
    
    @media (max-width: 1200px) {
        width: 100%;
        min-width: 0;
    }

}

html body{
    @media (max-width: 992px){
        height: auto;
        &.ifOverlay{
            overflow: hidden;
        }
        &.ifOverlayHeight{
            height: 100%;
        }
    }
}


.container{
    max-width: 1265px;
    @media (max-width: 992px) {
        padding: {
            left: $space;
            right: $space;
        }
    }
}
main, .cabinet-layout{
    @media (max-width: 992px) {
        padding: {
            top: 70px;
            bottom: 40px;
        }

   

        
    }
}
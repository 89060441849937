.contact-layout{
  .map-info{
    padding-left: 20px;
        .container{
          .map-detail {
            address{
              margin-bottom: 18px;
              font-size: 14px;
            }
            .telephone-list{
              li{
                &:not(:last-child){
                  margin-bottom: 5px;
                  font-family: $fontBold;
                  a{
                    color: #000000;
                    text-decoration: none;

                  }
                }
                
                font-size: 14px;
                &.box-item{
                  margin-top: 15px;
                }
              }
            }

          }
        }

  }
  @media (max-width: 992px){
    .map-info{
      padding-left: 0;
        .container{
          .map-detail {
            height: auto;
          }
        }

    }
  }
    @media (max-width: 600px){
      h1{
        text-align: center;
      }
      #map{
        width: 100%;
        height: 370px;
         float: none; 
      }
      .map-info{
        float: none;
        position: static;
        .container{
          .map-detail {
            width: 100%;  
            float: none;   
            padding-top: 25px;
            h4{
              font-size: 18px;
              text-transform: none;
              margin-bottom: 25px;
            }

            address{
              margin-bottom: 25px;
              font-size: 18px;
              strong{
                font-weight: normal;
                font-family: $fontRegular;
              }
            }
            .telephone-list{
              li{
                &:not(:last-child){
                  margin-bottom: 25px;
                  font-family: $fontBold;
                }
                font-size: 18px;
                &.box-item{
                  margin-top: 0;
                }
              }
            }

            .address{
              margin-bottom: 33px;

            }
          }

        }
      }

    }
}
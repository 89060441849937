
.modal{
  @media (max-width: 992px){
    .modal-dialog{
      .modal-content{
        border-radius: 0;
        h1{
          font-size: 18px;
          font-family: $fontRegular;
        }
      }

    }

  }
}
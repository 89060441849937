.w3-animate-zoom {
    animation: animatezoom 0.4s
}

@keyframes animatezoom {
    from {
        transform: scale(0)
    }
    to {
        transform: scale(1)
    }
}

.w3-animate-left{position:relative;animation:animateleft 0.4s}

@keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}


.w3-animate-opacity{animation:opac 0.8s}@keyframes opac{from{opacity:0} to{opacity:1}}
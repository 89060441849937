

.mobile-nav {
    visibility: hidden;
    opacity: 0;
    transition: all .2s linear 0s;
    @media (min-width: 993px){
      display: none;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
        font-weight: normal;
    }
    
    &.mobile-nav-open{
        visibility: visible;
        opacity: 1;
      @media (min-width: 993px){
        display: none;
      }
    }
    @media (max-width: 992px) {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        overflow: auto;
        z-index: 100;
        background: #ffffff;
        .nav-header {
            text-align: center;
            position: relative;
            .icon-logo {
                font-size: 76px;
            }
            a{
              display: inline-block;
              text-decoration: none;
            }
            .f-close {
                left: 20px;
                top: 23px;
            }
        }
        .nav-footer{
          padding: {
            left: $space;
            right: $space;
            top: 30px;
            bottom: 30px;
          }
          opacity: .7;

          p:not(:last-child){
            padding-bottom: 7px;
          }
        }
        .category-heading {
            font-size: 18px;
            font-family: "PFAgoraSansProBold";
            text-transform: none;
            padding: {
                left: 40px;
                right: $space;
                top: 30px;
                bottom: 12px;
            }
            &.special {
                color: $pink;
            }
        }
        .accordion-btn {
            border: none;
            outline: none;
            background: #ffffff;
            width: 100%;
            padding: {
                left: $space;
                right: $space;
                top: 7px;
                bottom: 7px;
            }
            margin: 5px 0;
            text-align: left;
            font-size: 20px;
            transition: .4s;
            position: relative;
            .w-icons-caret {
                transform: rotate(-90deg);
            }
            &.active {
              padding-left: 40px;
                .w-icons-caret {
                    position: absolute;
                    left: 24px;
                    top: 15px;
                    transform: rotate(90deg);
                }
            }
        }
        .accordion-panel {
            background: #ffffff;
            width: 100%;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.2s ease-out;
            li {
                margin: 5px 0;
                &.brand-item {
                    a {
                        text-transform: uppercase;
                    }
                }
            }
            a {
                font-size: 16px;
                padding: {
                    left: 40px;
                    right: $space;
                    top: 7px;
                    bottom: 7px;
                }
                display: block;
                text-decoration: none;
                text-transform: lowercase;
                color: #000000;
                &.special {
                    font-size: 18px;
                    font-family: "PFAgoraSansProBold";
                    text-transform: none;
                    padding: {
                        left: 40px;
                        right: $space;
                        top: 30px;
                        bottom: 12px;
                    }
                    color: $pink;
                }
            }
                .banner{
                    
                        display: block;
                        position: static;
                        width: auto;
                        text-decoration: none;
                        padding-bottom: 20px;
                        padding-top: 30px;
                        padding-left: 40px;
                        padding-right: $space;
                        margin: {
                            bottom: 10px;
                            top: 10px;
                        }
                        img{
                            display: none;
                        }
                        .title{
                            display: block;
                            position: static;
                            text-transform: none;
                            padding-top: 0;
                            font-size: 16px;
                            color: $pink;
                            font-family: $fontBold;
                            br {
                                display: none;
                            }
                        }
                    
                }            
        }
        #main_menu, .secondary-menu, .phone-list {
            li {
                margin: 5px 0;
            }
            a {
                font-size: 20px;
                padding: {
                    left: $space;
                    right: $space;
                    top: 7px;
                    bottom: 7px;
                }
                display: block;
                text-decoration: none;
                color: #000000;
            }
            padding: {
              
              bottom: 18px;
              
            }
            // border-bottom: 1px solid $pink;
            &:after{
              content: '';
              display: block;
              background: $pink;
              height: 1px;
              margin: {
                left: $space;
                right: $space;
                top: 18px;
              }
            }
        }
        .phone-list{
            a{
                color: $pink;
            }
        }

    }
}
.histiry-list{
  .one-order-wrap:not(:last-child){
    padding-bottom: 20px;
  }
  .cart-list{
    @include minusMargins(5px);
  }
  .order-details{
    font-size: 0;
    @include minusMargins(5px);
    > li{
      padding-bottom: 6px;
      padding-left: 5px;
      padding-right: 5px;
      font-size: 0;
      width: 100%;
      display: inline-block;
      vertical-align: top;
      &:last-child{
        span{
          &:last-child{
            font-size: 20px;
            font-family: $fontBold;
            &:after{
              content: 'грн.';
              display: inline-block;
              font-size: 13px;
              padding-left: 5px;
            }

          }
        }
      }
      span{
        display: inline-block;
        vertical-align: top;
        width: 50%;
        font-size: 13px;
        &:first-child{
          color: #898989;
          overflow: hidden;
          &:after{
            content: "";
            height: 1px;
            display: inline-block;
            vertical-align: bottom;
            width: 100%;
            margin-right: -100%;
            border-bottom: 1px dotted #898989;
          }
        }
        &:last-child{
          padding-left: 5px;
          font-family: $fontBold;
        }
      }
      &:first-child{
        p{
          font-size: 20px;
          font-family: $fontBold;
          em{
            font-family: $fontRegular;
            font-style: italic;
            font-size: 13px
          }
        }
      }
    }
  }
  .cart-list{
    li{
      .spangroup{

        &:last-child{
          span{
            &:last-child{
              color: #000000;
              padding: {
                right:0;
                bottom: 0;
                top: 0;
              }

            }
          }

        }
      }

    }
  }
  .product-prop-list{
    li{
      span{
        font-size: 13px;
      }
    }
  }
  .accordion-btn{
    display: none;
  }

  @media (max-width: 768px){

    .accordion-btn{
      display: block;
        em{
          font-size: 20px;
          color: #898989;
          font-family: $fontBold;
        }

        &.active{
          .spangroup{
            &:last-child{
              
              .icon-seagull-down{
                &:before{
                  content: "\e908"
                }
              }

            }

          }

        }


    }    
    .order-details{

      > li{
 
        &:first-child{
          p{
            display: none;
          }
        }
        
      }
    }    
    .accordion-panel{
      width: 100%;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;         
    }

  }
}
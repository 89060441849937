header {
    .header-wrap{
        padding: {
            left: 20px;
            right: 20px;
        }
        .logotype{
            margin-left: 20px;
        }
    }
    .nav{
        width: 60%;
    } 
    @media (min-width: 1200px){
        .big-links, .my-dropdown-submenu{
            left: 0;
        }
        
    }
        @media (max-width: 1280px){
            .line-bottom{
                padding-right: 0;
        .form-search{
            input{
                transition: width .2s linear;
                width: 107px;
                &:focus{
                    width: 300px;
                }
            }
        }                
            }
        }
    @media (max-width: 1200px){
        .line-top{
            padding-left: 200px;
            text-align: center;
            .call, .cart, .link-modal{
                padding: {
                    left: 17px;
                    right: 30px;
                }
            }
        }
        .nav{
            ul{
                a {
                    padding: {
                        left: 8px;
                        right: 8px;
                    }
                }
            }
        }

    }
    .icon-burg.special{
        display: none;
        
    }
    // @media (max-width: 1160px){
    //     .icon-burg.special{
    //         text-align: center;
    //         display: inline-block;
    //         background: #ffffff;
           
    //         padding: {
    //             left: 18px;
    //             right: 18px;
    //             top: 8px;
    //             bottom: 8px;
    //         }
    //         &:before{
    //             color: $pink;
    //         }
    //     }
    //     .nav.hide-small.hide-medium{
    //         width: auto;
    //         padding-left: 25px;
    //         ul {
    //             max-height: 0px;
    //             opacity: 0;
    //             transition: max-height .3s linear;
    //             width: auto;
    //             li{
    //                 float: none;
    //                 background: #ffffff;
                   
    //             }
    //             &.open{
    //                 max-height: 185px;
    //                 opacity: 1;
    //                 a{
    //                     color: $pink;
    //                     padding: {
    //                         left: 18px;
    //                         right: 18px;
    //                     }
    //                 }
    //                 position: relative;
    //                 border-top: 3px solid #ff366c;
    //                 &:after{
    //                     content: "";
    //                         position: absolute;
    //                         top: -7px;
    //                         left: 19px;
    //                         width: 0;
    //                         height: 0;
    //                         border-style: solid;
    //                         border-width: 0 7px 6px 7px;
    //                         border-color: transparent transparent #ff366c 

    //                 }
    //             }
                
    //         }
    //     }

    // }
    
    @media (max-width: 992px) {
        .my-dropdown-submenu{
            display: none;
        }
        .line-bottom{
            &.opened{
              .my-dropdown-submenu{
                  display: block;
              }  
            }
        }
        .header-wrap{
            padding: 0;
        }
        box-shadow: none;
        height: auto;
        .line-top {
            height: 70px;
            padding: {
                left: 0;
            }
            font-size: 0;

            .cart {
                min-width: 0;
                margin: {
                    top: 0;
                }
                display: inline-block;
                height: 100%;
                vertical-align: middle;
                @include vertical();
                #cartCountGoods {
                    font-size: 10.5px;
                }
                .basket-amount {
                    position: absolute;
                    @include center();
                    // top:56%;
                    color: #ffffff;
                }
            }
            .call,
            .rigth-menu {
                padding: {
                    top: 0;
                }
                display: inline-block;
                vertical-align: middle;
            }
            .call {
                width: 70%;
                text-align: left;
                position: static;
            }
            .call, .cart, .link-modal{
                padding: {
                    left: 0;
                    right: 0;
                }
            }

            .rigth-menu{
                text-align: right;
                width: 30%;
                font-size: 0;
                li{
                    display: inline-block;
                    padding: {
                        left: 15px;
                        right: 15px;
                    }
                    &:last-child{
                        padding: {
                            right: 0;
                        }
                    }
                    &:first-child{
                        padding: {
                            left: 0;
                        }
                    }
                }
            }
            .icon-burg {
                font-size: 17px;
            }
            .icon-auth {
                font-size: 10px;
                opacity: .5;
                display: inline-block;
                vertical-align: middle;
            }
            .icon-auth-wrap {
                height: 30px;
                width: 30px;
                border-radius: 50%;
                background-color: $lGray;
                overflow: hidden;
                display: inline-block;
                vertical-align: middle;
                @include vertical();
            }
            .top-elem {
                display: inline-block;
                vertical-align: middle;
                &:not(.special){
                    padding: {
                        left: 15px;
                        right: 15px;
                        top: 15px;
                        bottom: 15px;
                    }
                    margin: 9.5px 0;

                }
                &.special{
                    position: absolute;
                    top: -4px;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    overflow: hidden;
                }
                &:first-child {
                    padding: {
                        left: 0;
                    }
                }
                &.special-last {
                    padding: {
                        right: 0;
                    }
                }
                a{
                    display: inline-block;
                    text-decoration: none;
                }
            }

        }
        .line-bottom {
            height: 54px;
            padding: {
                right: 0;
            }
            .big-links{
                .drop-menu{
    
                            
                            a{
                                background: $lGray;
                                border-right: 1px solid rgba(#000000, .3);
                                color: #000000;
                            }
                            .w-icons-caret{
                                background-position: -76px -54px;
                            }
                            &.active{
                                a{
                                    background: $pink;
                                    color: #ffffff;
                                    border-right: 0;
                                }
                                .w-icons-caret{
                                    background-position: -76px -47px;
                                }

                            }
                }
                li{
                    transition: all .2s;
                }
                ul{
                    li:last-child{
                        background: $lGray;
                        position: absolute;
                        right: 0;
                    }
                }
            }
            &.opened{
                .big-links{
                    ul{

                        li:not(.drop-menu){
                            z-index: -1;
                        }
                        .drop-menu{
                            position: relative;
                            width: 100%;
                            .w-icons-caret{
                                display: none;
                            }
                            .f-close{
                                right: $space;
                                top: 50%;
                                transform: translate(0%, -50%);
                                &:after, &:before{
                                    background-color: #ffffff;
                                }
                                display: block;
                            }
                        }
                    }
                }

            }
            .big-links {
                @include minusMargins($space);
                height: 54px;
                padding: {
                    top: 0;
                }
                float: none;
                ul {
                    .drop-menu{
                        .f-close{
                            display: none;
                        }
                    }
                    li {
                        width: 50%;
                        a {
                            width: 100%;
                            text-align: center;
                            &:first-child {
                                padding: {
                                    top: 17px;
                                    bottom: 17px;
                                    left: 0;
                                    right: 0;
                                }
                            }
                            .help-text {
                        
                                padding: {
                                    left: 0;
                                }
                            }
                        }
                        .w-icons-caret {
                            position: static;
                            display: inline-block;
                            vertical-align: middle;
                            margin: {
                                top: 2px;
                                left: 5px;
                            }
                        }
                        .w-icons-sales {
                            margin: {
                                right: 7px;
                            }
                        }
                    }
                }
            }
            .form-search {
                position: relative;
                @include minusMargins($space);
                input[type="search"]{
                    -webkit-appearance: none;
                    border-radius: 0;
                }
                input {
                    width: 100%;
      
                    -webkit-appearance: none;
                    border-radius: 0;
                    height: 50px;
                    background: #ffffff;
                    font-size: 16px;
                    border-bottom: 1px solid rgba(#cacaca, .78);
                    padding: {
                        left: $space;
                    }
                    &:focus {
                        border-bottom-color: #ff366c;
                        width: 100%;
                    }
                }
                input::-webkit-search-cancel-button {
                    display: none;
                }

                .w-icons-search {
                    opacity: .5;
                    top: 16px;
                    right: $space;
                }
            }
        }
            .icon-logo {
                font-size: 76px;
            }


    }


}


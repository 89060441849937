.section-map{
  position: relative;
  .icon-logo{
    font-size: 0;
  }
  @media (max-width: 992px){
    #map{
      height: 370px;

    }
    a{
      position: absolute;
      text-decoration: none;
      left: 26px;
      top: 0;
      .icon-logo{
        font-size: 76px;
        &:before{
          color: #898989;
        }
      }
    }
  }
}
@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon/icomoon.eot');
  src:  url('../../fonts/icomoon/icomoon.eot#iefix') format('embedded-opentype'),
    // url('../../fonts/icomoon/icomoon.woff2') format('woff2'),
    url('../../fonts/icomoon/icomoon.ttf') format('truetype'),
    url('../../fonts/icomoon/icomoon.woff') format('woff'),
    url('../../fonts/icomoon/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arr-r:before {
  content: "\e900";
}
.icon-auth:before {
  content: "\e901";
}
.icon-burg:before {
  content: "\e902";
}
.icon-filter:before {
  content: "\e903";
}
.icon-logo:before {
  content: "\e904";
}
.icon-mirrolab:before {
  content: "\e905";
}
.icon-seagull-down:before {
  content: "\e906";
}
.icon-seagull-up:before {
  content: "\e908";
}
.icon-sort:before {
  content: "\e907";
}


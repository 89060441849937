.auth-modal {
    @media (min-width: 993px){
        display: none !important;
    }
    z-index: 1001;
    .w3-modal-content {
        text-align: left;
        border: none;
        padding: {
            left: $space;
            right: $space;
            top: 20px;
            bottom: 30px;
        }
        margin: {
            right: 0;
            top: 60px;
        }
        width: 199px;
        .w3-modal-header{
            padding-bottom: 30px;
            font-size: 0;
        }
        .modal-heading{
            font-size: 16px;
            font-family: $fontBold;
            padding-left: 8px;
        }
        .modal-heading, .icon-auth-wrap{
            display: inline-block;
            vertical-align: middle;
        }
        .icon-auth-wrap{
            width: 40px;
            height: 40px;
        }
        .icon-auth{
            opacity: 1;
            font-size: 17px;
        }
        .auth-list {
            a {
                position: relative;
                display: inline-block;
                vertical-align: middle;
                font-size: 16px;
                color: #000000;
                text-decoration: none;
                padding: {
                    top: 7px;
                    bottom: 7px;
                }
                text-transform: lowercase;
            }
            li:not(.w3-modal-header) {
                margin: {
                    left: 5px;
                    right: 5px;
                }
            }
        }
        .w-icons-caret {
            position: absolute;
            right: 12px;
            top: 4px;
            transform: rotate(180deg);
        }
    }
}
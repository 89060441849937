@media (max-width:600px){
	.hide-small{display:none!important}
}
@media (max-width:992px) and (min-width:601px){
	.hide-medium{display:none!important}
}
@media (min-width:993px){
	.hide-large{display:none!important}
}

@media (min-width:1201px){
	.hide-desktop-custom{display:none!important}
}

@media (max-width:1200px) and (min-width:601px){
	.hide-mobile-custom{display:none!important}
}

@mixin size($width,$height) {
  width: $width;
  height: $height; }
@mixin justify {
  font-size: 0;
  text-align: justify;
  text-justify: newspaper;
  li {
    display: inline-block;
    vertical-align: middle; }
  &:after {
    height: 1px;
    margin-top: -1px;
    content: "";
    display: inline-block;
    width: 100%; } }
@mixin vertical {
  font-size: 0;
  text-align: center;
  .help {
    display: inline-block;
    vertical-align: middle; }
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    @include size(.1%,100%);
    margin-left: -.1%; } }

@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%); } }

@mixin minusMargins($margins) {
  margin-left: -$margins;
  margin-right: -$margins; }
@mixin clearfix() {
  &:after, &:before {
    content: "";
    display: teble;
    clear: both; } }
